import React, { useEffect } from "react";
import { wpConnect } from "../../../Services/api";
import { getConnection } from "../../../Reducer/whatsAppSlice";
import { useDispatch } from "react-redux";
// import LeftSide from "./components/leftSide";

const WhatsApp = () => {
    const dispatch = useDispatch();
    let bid = '';
    let wid = '';
    let code = '';

    useEffect(()=>{
        dispatch(getConnection());
    },[])
    function connectWhatsApp() {
        window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '544704651303656', feature: 'whatsapp_embedded_signup' });
        window.FB.login(function (response) {
            if (response.authResponse) {
                const code = response.authResponse.code;
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: '401007423042134', // configuration ID goes here
            response_type: 'code',    // must be set to 'code' for System User access token
            override_default_response_type: true, // when true, any response types passed in the 
            extras: {
                feature: "whatsapp_embedded_signup",
                sessionInfoVersion: 3  //  Receive Session Logging Info
            }
        });
    }

    const sessionInfoListener = async (event) => {

        if (event.origin == null) {
            return;
        }
        if (!event.origin.endsWith("facebook.com")) {
            return;
        }

        try {
            const data = JSON.parse(event.data);
            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                if (data.event === 'FINISH') {
                    const { phone_number_id, waba_id } = data.data;
                    bid = phone_number_id;
                    wid = waba_id;
                    console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
                    const body = { code: code, id: phone_number_id, pin: '123456', wb_id: waba_id, name: "testing", phone: "5555555555" };
                    const connect = await wpConnect(body);
                    console.log("connect------------------------->", connect);
                }
                else if (data.event === 'ERROR') {
                    const { error_message } = data.data;
                    console.error("error ", error_message);
                }
                else {
                    const { current_step } = data.data;
                    console.warn("Cancel at ", current_step);
                }
            }
        } catch (e) {
            const params = new URLSearchParams(event.data);
            const jsonObject = await Object.fromEntries(params.entries());
            console.log('vvvvvvv-------------->', jsonObject);
            if (jsonObject.code) {
                code = jsonObject.code;
                const body = { code: jsonObject.code, id: bid, pin: '123456', wb_id: wid, name: "testing", phone: "5555555555" };
                const connect = await wpConnect(body);
                console.log("connect--------------2222----------->", connect);
            }
        }
    };
    window.addEventListener('message', sessionInfoListener);

    // 'templates'

  return (
    <section class="social-main">
      <div class="container">
        <div class="row justify-content-center align-items-start">
        <button onClick={connectWhatsApp}>WhatsApp</button>
        </div>
      </div>
    </section>
  );
};

export default WhatsApp;
