import React, { useState } from "react";
import { useSelector } from "react-redux";

const Installation = () => {
  const [isCopied, setIsCopied] = useState(false);
  const { userDetails } = useSelector((state) => state.auth);

  const code = `(function(d, w) {
      d.body.appendChild(d.createElement('div')).id = 'root';
      var s = d.createElement('script');
      s.async = true;
      s.src = 'https://staging.digittrix.com/bundle.js';
      s.onload = function() {
          if (typeof w.ChatWidget === 'function') {
              w.ChatWidget('root',${userDetails.key});
          } else {
              console.error('window.ChatWidget is not a function'(function(d, w) {
                d.body.appendChild(d.createElement('div')).id = 'root';
                var s = d.createElement('script');
                s.async = true;
                s.src = 'https://staging.digittrix.com/bundle.js';
                s.onload = function() {
                    if (typeof w.ChatWidget === 'function') {
                        w.ChatWidget('root',${userDetails.key});
                    } else {
                        console.error('window.ChatWidget is not a function');
                    }
                };
                d.head.appendChild(s);
            })(document, window););
          }
      };
      d.head.appendChild(s);
  })(document, window);`;
  return (
    <>
      <h3 className="chat5-head">
        Install the chat widget manually on your website
      </h3>
      <div className="install-box">
        <p>
          Paste the widget code below into the code of the pages where you want
          to enable the chat. Right before the <code>&lt;/head&gt;</code> tag is
          the best option.
        </p>

        {/* <!-- Code Box --> */}
        <div className="code-box">
          <button
            className="copy-btn"
            style={{ float: "right" }}
            onClick={() => {
              navigator.clipboard.writeText(code);
              setIsCopied(true);
            }}
          >
            <i
              className="fa fa-clone"
              aria-hidden="true"
              style={{ color: "#959595", marginRight: "10px" }}
            ></i>
            {isCopied ? "Copied" : "Copy"}
          </button>
          <pre>
            <code>{code}</code>
          </pre>
        </div>

        {/* <!-- Copy button --> */}

        {/* <!-- Information boxes --> */}
        <div className="info-box mt-3">
          <i
            className="bi bi-info-circle-fill"
            style={{ color: "#5D5D5D", marginRight: "10px" }}
          ></i>
          <div>
            After pasting the widget code, open your website to initiate the
            chat widget.
          </div>
        </div>

        <div className="info-box mt-2">
          <i
            className="bi bi-info-circle-fill"
            style={{ color: "#5D5D5D", marginRight: "10px" }}
          ></i>
          <div>
            Need help? Check the full installation instructions.
            <a href="#" className="help-link">
              Help article
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Installation;
