import React from 'react'
import ReactTimeAgo from 'react-time-ago';
import { mediaUrl } from '../../../../Network/path';

const Message = ({ me, message, time, file, fileType }) => {
    return (
        <div className={`${me ? "chat-message-left" : "chat-message-right"} mb-2`} >
            <small className="d-block text-muted">  <ReactTimeAgo
                date={time * 1000}
                locale="en-US"
                timeStyle="twitter-minute-now"
            /></small>
            {(file && fileType) ? <div className={`message-text ${me ? "bg-light" : "bg-warning"} p-2 rounded d-inline-block`}>
                {fileType === "image" && <img src={`${mediaUrl}${file}`} style={{ maxWidth: "300px", maxHeight: "300px" }} />}

            </div> : <div className={`message-text ${me ? "bg-light" : "bg-warning"} p-2 rounded d-inline-block`}>
                {message}
            </div>}

        </div >
    )
}

export default Message;