import axios from "axios";
import React, { useState } from "react";
import { secondUrl } from "../../../Network/path";
import { instaLogin, linkInstagram } from "../../../Services/api";
import { toast } from "react-toastify";

const InstaConnection = () => {
  const [instaAccounts, setInstaAccounts] = useState([]);
  const handleInstagram = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const body = { accessToken: response.authResponse.accessToken };
          getInstagramAccount(body);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "pages_show_list,instagram_basic,pages_read_engagement,instagram_manage_messages,pages_messaging,pages_manage_metadata,business_management",
      }
    );
  };

  const getInstagramAccount = async (body) => {
    try {
      const res = await instaLogin(body);
      if (res.data.status) {
        const accounts = res.data.data;
        if (accounts.length > 0) {
          let new_accounts = [];
          for (let i = 0; i < accounts.length; i++) {
            const obj = {
              connected_id: accounts[i].id,
              type: "instagram",
              name: accounts[i].name,
              access_token: accounts[i].access_token,
              status: "off",
            };
            new_accounts = [...new_accounts, obj];
          }
          setInstaAccounts(new_accounts);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeInstaStatus = async (el) => {
    try {
      let filter = instaAccounts.filter(
        (page) => page.connected_id !== el.connected_id
      );
      filter = [
        ...filter,
        { ...el, status: el.status === "on" ? "off" : "on" },
      ];
      setInstaAccounts(filter);
    } catch (error) {
      console.log(error);
    }
  };

  const connectInstagram = async () => {
    try {
      const response = await linkInstagram(instaAccounts);
      if (response.data.status) {
        toast.success("Account connected");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <button onClick={handleInstagram}>Connect With Instagram</button>

      <hr />
      {instaAccounts &&
        instaAccounts.length > 0 &&
        instaAccounts.map((account) => (
          <div>
            <h4>{`${account.name}`}</h4>
            <button onClick={() => changeInstaStatus(account)}>
              {account.status === "on" ? "disconnect" : "connect"}
            </button>
          </div>
        ))}

      <hr />
      {instaAccounts.length > 0 && (
        <button onClick={connectInstagram}>Connect Instagram accounts</button>
      )}
    </>
  );
};

export default InstaConnection;
