import axios from "axios";
import { secondUrl } from "../Network/path.js";


export const fetchChat = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    const response = await axios.post(`${secondUrl}v1/chat_widget_members`, body, {
      headers: {
        "x-access-token": token,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const chatSearchApi = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v3/ChatSearch`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const searchChatUsers = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v3/searchingChatUser`, body, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const AddNewLogin = async (body) => {
  try {
    const response = await axios.post(`${secondUrl}v3/Login`, body);
    return response;
  } catch (error) {
    alert(error.message);
    throw error;
  }
}

export const updateEngage = async (data) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v3/updateEngage`, data, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error) {
    throw error;
  }
};

export const resendOtp = async (data) => {
  try {
    return await axios.post(`${secondUrl}v1/resendOtp`, data);
  } catch (error) {
    throw error
  }
}

export const addSocialApi = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v1/MakeSocial`, body, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (error) {
    throw error
  }
}

export const linkSocialApi = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v1/LinkSocial`, body, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (error) {
    throw error
  }
}

export const existingConnectionApi = async () => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.get(`${secondUrl}v1/connections`, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (error) {
    throw error;
  }
}

export const getFbMessages = async (body) => {
  try {
    const token = localStorage.getItem("tokenk")
    return await axios.post(`${secondUrl}v5/fbmessages`, body, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (error) {
    throw error
  }
}

export const verifyOtpApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v1/verifyotp`, body)
  } catch (error) {
    throw error
  }
}

export const registerApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v1/register`, body)
  } catch (error) {
    throw error
  }
}

export const sendFbMessage = async (body) => {
  try {
    const token = localStorage.getItem("tokenk")
    return await axios.post(`${secondUrl}v5/sendfbmessage`, body, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (error) {
    throw error;
  }
}



export const linkInstagram = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v7/insta-link`, body, {
      headers: {
        "x-access-token": token
      }
    });
  } catch (error) {
    throw error;
  }
}

export const addConnectionApi = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v1/addSocial`, body, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (error) {
    throw error
  }
}

export const wpConnect = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v4/register`, body, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (error) {
    throw error
  }
}

export const getDetailsWithKeyApi = async (body) => {
  try {
    return await axios.post(`${secondUrl}v3/Verify`, body)
  } catch (error) {
    throw error;
  }
}

export const getSocialConnection = async () => {
  try {
    const token = localStorage.getItem("tokenk")
    return await axios.get(`${secondUrl}v1/social-connections`, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (error) {
    throw error
  }
}

export const getConnectionList = async () => {
  try {
    const token = localStorage.getItem("tokenk")
    return await axios.get(`${secondUrl}v4/connectionsList`, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (e) {
    throw e
  }
}

export const sendInstagramSms = async (body) => {
  try {
    const token = localStorage.getItem("tokenk");
    return await axios.post(`${secondUrl}v7/sendinstamsg`, body, {
      headers: {
        "x-access-token": token
      }
    })
  } catch (error) {
    throw error
  }
}

export const instaLogin = async (body) => {
  return await axios.post(`${secondUrl}v7/instalogin`, body)
}